import { useParams } from "react-router-dom";
import { WebBulletin } from "../../components/Bulletin/WebBulletin";
import { usePublicContext } from "../../public/PublicContext";

export const BulletinPublicView = () => {
  const { announcements, bulletins, series, sermons } = usePublicContext();
  const { slug } = useParams();

  if (!series || !sermons || !announcements || !bulletins) return <></>;

  const sermon = sermons.find((x) => x.slug === slug);
  if (!sermon) return <>Sermon not yet published</>;

  const bulletin = bulletins[sermon.id + "-published"];
  if (!bulletin) return <>Bulletin not published</>;

  return (
    <div>
      <WebBulletin
        bulletin={bulletin}
        sermon={sermon}
        series={series}
        sermons={sermons}
        announcements={announcements}
      />
    </div>
  );
};
