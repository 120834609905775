import { ReactNode } from "react";
import { usePageUtils } from "../usePageUtils";
import { Hero } from "../Hero/Hero";
import { MissionBanners, } from "../ImageLibrary/imageLibrary";
import "./Missions.scss";
import { WebLink } from "../../components/contact-components/WebLink/WebLink";
import { CTA } from "../ForMembers/ForMembers";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faPrayingHands, faPenNib, faHandHoldingHeart, faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpeakersAndOpportunitiesData } from "./SpeakersAndOpportunitiesData";

export const Missions = () => {
  const { setTitleAndGroup } = usePageUtils();

  setTitleAndGroup("Missions", "General");

  return (
    <div>
      <Hero img={MissionBanners} width={2560} height={1137}/>
      <div className="content-holder">
        <MissionsIntro/>
        <ThreeSteps/>
        <CTA>
          Sign up at <WebLink link="bfec.sg/missionssignup"/>
        </CTA>
        <SpeakersAndOpportunies/>
      </div>
    </div>
  );
};

const MissionsIntro = () => (
  <>
    <h2>Welcome to the BFEC Missions Microsite!</h2>
    <p>
      The Great Commission given by Christ to His church remains unfinished. 
      As members of BFEC, how are we doing our part in the proclamation and demonstration of the gospel to those who have yet to hear and experience it? And how may we co-labour with our gospel partners elsewhere in the world in making disciples of all nations? 
    </p>
    <p>
      Our BFEC church-wide journey this year seeks to re-focus all of us on our church-wide mission.<br/> Will <i>you</i> be a part of it?
    </p>
  </>
)

const ThreeSteps = () => (
  <div id="three-steps-wrapper">
    <h3>Three Simple Steps</h3>
    <div id="three-steps">

      <Step num={1} icon={faPrayingHands} 
      >
        <p>Pray and ask God where and what He is leading you to.</p>
      </Step>
      <div className="arrow">
        <FontAwesomeIcon icon={faArrowRight} size={"2x"}/>
      </div>
      <Step num={2} icon={faPenNib} 
        >
          <p>Register your interest at the link below to sign up for missions either as an individual, or as a group.</p>
        </Step>
      <div className="arrow">
        <FontAwesomeIcon icon={faArrowRight} size={"2x"}/>
      </div>
      <Step 
        num={3} 
        icon={faHandHoldingHeart} 
      >
        <p>
          Attend the mission trip, serving the community God sends you to. 
          <br/>
          Pray for next steps in your journey, preferably to visit and invest in the same community again.
        </p>
      </Step>
    </div>
  </div>
);

const Step = (props: {
  num: number;
  icon: IconDefinition;
  children: ReactNode
}) => (
  <div className="step">
    <FontAwesomeIcon icon={props.icon} size={"3x"} color="#ab8454"/>
    <h4>STEP {props.num}</h4>
    {props.children}
  </div>
);

const SpeakersAndOpportunies = () => {
  return (
    <div>
      <h3>Speakers & List of Opportunities for Churchwide Mission 2024</h3>
      <table id="speakers-and-opportunities">
        <thead>
          <tr>
            <th>Date</th>
            <th>Topic and Speaker</th>
            <th>Bulletin / Opportunities</th>
          </tr>
        </thead>
        <tbody>
          {
            SpeakersAndOpportunitiesData.map((data, index) => (
              <ContentRow 
                key={data.date}
                date={data.date} 
                speaker={data.speaker}>
                  {data.content}
              </ContentRow>
            ))
          }
          </tbody>
        </table>
    </div>
  )
};

const ContentRow = (props: {
  date: string;
  speaker: string;
  children: ReactNode;
}) => (
  <tr className="sao-row">
    <th>
      <p className="mobile-headers">Date: </p>
      {props.date}
    </th>
    <td>
      <p className="mobile-headers">Speaker: </p>
      {props.speaker}
    </td>
    <td>
      {props.children}
    </td>
  </tr>
);