import {
  AnnouncementInterface,
  Bulletin,
  SermonInterface,
  SpeakerSeriesData,
} from "../Interfaces/Interfaces";
import "./WebBulletin.scss";
import { BulletinAnnouncements } from "./BulletinAnnouncements";
import { BulletinAddress } from "./BulletinAddress";
import { BulletinGivingMessage } from "./BulletinGivingMessage";
import { BulletinFooter } from "./BulletinFooter";
import {
  BulletinSermonDetails,
  formatSermonDate,
} from "./BulletinSermonDetails";
import { BulletinHeader } from "./BulletinHeader";

const BulletinPage1 = (props: {
  headerPrimary: string;
  headerSecondary: string;
  bannerLink: string;
  sermon: SermonInterface;
  sermonNotes: string;
}) => {
  const formattedSermonDate = formatSermonDate(props.sermon);

  return (
    <div className="page">
      <div className="page-content">
        <BulletinHeader
          formattedSermonDate={formattedSermonDate}
          headerPrimary={props.headerPrimary}
          headerSecondary={props.headerSecondary}
        />
        <img
          id="bulletin-banner"
          src={props.bannerLink}
          alt="Bulletin banner"
        />
        <BulletinSermonDetails
          sermon={props.sermon}
          sermonNotes={props.sermonNotes}
        />
        <BulletinGivingMessage print />
        <BulletinAddress />
      </div>
    </div>
  );
};

const BulletinPage2 = (props: {
  bulletin: Bulletin;
  sermon: SermonInterface;
  sermons: SermonInterface[];
  series: SpeakerSeriesData[];
  announcements: AnnouncementInterface[];
}) => (
  <div className="page">
    <div className="page-content">
      <div id="keep-this" className="print-only">
        Keep this for your prayer and participation
      </div>
      <BulletinAnnouncements
        bulletin={props.bulletin}
        sermon={props.sermon}
        sermons={props.sermons}
        series={props.series}
        announcements={props.announcements}
      />
      <BulletinGivingMessage screen />
      <BulletinFooter />
    </div>
  </div>
);

export const WebBulletin = ({
  bulletin,
  sermon,
  series,
  sermons,
  announcements,
}: {
  bulletin: Bulletin;
  series: SpeakerSeriesData[];
  sermon: SermonInterface;
  sermons: SermonInterface[];
  announcements: AnnouncementInterface[];
}) => {
  return (
    <div id="web-bulletin">
      <BulletinPage1
        headerPrimary={bulletin.header_primary}
        headerSecondary={bulletin.header_secondary}
        bannerLink={bulletin.banner_link}
        sermon={sermon}
        sermonNotes={bulletin.sermon_notes}
      />
      <BulletinPage2
        bulletin={bulletin}
        sermon={sermon}
        sermons={sermons}
        series={series}
        announcements={announcements}
      />
    </div>
  );
};
