import "./TimelineItem.scss";

export const TimelineItem = (props: {
  date: string;
  children: JSX.Element | JSX.Element[] | string;
}) => (
  <div className="timeline-item">
    <div className="timeline-item-date">{props.date}</div>
    <div className="timeline-item-text">{props.children}</div>
  </div>
);
