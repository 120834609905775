import { WhatsOnHeroes } from "../ImageLibrary/imageLibrary";
import { Hero } from "../Hero/Hero";
import "./WhatsOn.scss";
import "./Announcements.scss";
import { ListingWithSearch } from "../../components/ListingWithSearch/ListingWithSearch";
import { usePublicContext } from "../PublicContext";
import {
  emptySQLNullString,
  SQLNullTime,
} from "../../components/Interfaces/Interfaces";
import { AnnouncementCard } from "./AnnouncementCard";

export const WhatsOnContainer = () => {
  const ctx = usePublicContext();

  const announcements = [...(ctx.announcements || [])];

  if (ctx.sermons) {
    const latestPublishedBulletin = ctx.sermons
      .filter((x) => x.sermon_date.Valid)
      .sort((a, b) => (a.sermon_date.Time < b.sermon_date.Time ? 1 : -1))
      .map((x) => ctx.bulletins[x.id + "-published"])
      .filter((x) => x != null)
      .shift();

    if (latestPublishedBulletin) {
      const updatedDateTime: SQLNullTime = {
        Valid: latestPublishedBulletin.last_updated !== "",
        Time: latestPublishedBulletin.last_updated || "",
      };

      announcements.unshift({
        id: 0,
        title: "Upcoming Meetings",
        image_link: emptySQLNullString,
        image_alt: emptySQLNullString,
        content: "blah blah",
        bulletin_text: "",
        slug: "upcoming-meetings",
        author: latestPublishedBulletin.last_updated_by || "",
        last_edited_by: emptySQLNullString,
        created_on: updatedDateTime,
        last_edited: updatedDateTime,
        published_on: updatedDateTime,
        pinned: true,
        files: [],
        synopsis: emptySQLNullString,
      });
    }
  }

  return (
    <div>
      <Hero img={WhatsOnHeroes} width={4060} height={2310} />
      <ListingWithSearch
        elementName="announcements"
        items={announcements}
        card={(announcement) => (
          <AnnouncementCard key={announcement.id} data={announcement} />
        )}
        searchFields={["title", "content"]}
      />
    </div>
  );
};
