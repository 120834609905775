import axiosRetry from "axios-retry";
import axios, { Method } from "axios";
import * as Sentry from "@sentry/react";

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

const callAPI = (method: Method, url: string, payload?: any) =>
  axios
    .request({
      method: method,
      url: BACKEND_URL + url,
      data: payload,
      headers: { "Cache-Control": "no-cache" },
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 403) {
          Sentry.captureException(err);
        }

        return err.response.status;
      }
    });

export const PublicAPI = () => {
  return {
    getData: () => {
      return callAPI("GET", "/api/data/?t=" + Math.floor(Date.now() / 5000));
    },
    getLatestSermon: () => {
      return callAPI(
        "GET",
        "/api/sermons/latest/?t=" + Math.floor(Date.now() / 5000)
      );
    },
  };
};
