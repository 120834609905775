import {
  AnnouncementInterface,
  Bulletin,
  SermonInterface,
  SpeakerSeriesData,
} from "../Interfaces/Interfaces";
import parse from "html-react-parser";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const BulletinAnnouncements = (props: {
  bulletin: Bulletin;
  sermon: SermonInterface;
  sermons: SermonInterface[];
  series: SpeakerSeriesData[];
  announcements: AnnouncementInterface[];
}) => {
  return (
    <div id="announcements">
      <UpcomingSeries
        series={props.series}
        sermon={props.sermon}
        sermons={props.sermons}
        bulletin={props.bulletin}
      />
      {props.bulletin.announcement_ids.map((id) => (
        <BulletinAnnouncement
          key={id}
          id={id}
          announcements={props.announcements}
        />
      ))}
      <UpcomingMeetings html={props.bulletin.upcoming_meetings} />
    </div>
  );
};
const UpcomingSeries = (props: {
  series: SpeakerSeriesData[];
  sermon: SermonInterface;
  sermons: SermonInterface[];
  bulletin: Bulletin;
}) => {
  const upcomingSeries = props.series.find(
    (x) => x.id === props.bulletin.sermon_series_id
  );
  const seriesSermons = props.sermons.filter(
    (x) => x.series.id === props.bulletin.sermon_series_id
  );
  const upcomingSermons = seriesSermons
    .filter((x) => x.sermon_date.Time > props.sermon.sermon_date.Time)
    .reverse();
  const label =
    upcomingSermons.length === seriesSermons.length ? "Upcoming Series: " : "";

  if (!upcomingSeries) return <></>;

  return (
    <BulletinAnnouncementWrapper
      id="upcoming-series"
      testId="upcoming-series"
      title={`${label}${upcomingSeries.name}`}
      synopsis={
        <>
          {parse(upcomingSeries.content, { trim: true })}
          <UpcomingSeriesTable sermons={upcomingSermons} />
        </>
      }
      imageLink={upcomingSeries.image_link.String}
    />
  );
};

const UpcomingSeriesTable = (props: { sermons: SermonInterface[] }) => {
  if (props.sermons.length === 0) return <></>;

  const showPassage =
    props.sermons.filter((x) => x.main_passage && x.main_passage !== "")
      .length > 0;

  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Title</th>
          {showPassage && <th>Main Passage</th>}
          <th>Speaker</th>
        </tr>
      </thead>
      <tbody>
        {props.sermons.map((x) => (
          <tr key={x.id}>
            <td style={{ whiteSpace: "nowrap" }}>
              {format(new Date(x.sermon_date.Time), "d MMM")}
            </td>
            <td>{x.title}</td>
            {showPassage && <td>{x.main_passage ? x.main_passage : "-"}</td>}
            <td>{x.speaker.map((x) => x.name).join(" & ")}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const BulletinAnnouncement = ({
  id,
  announcements,
}: {
  id: number;
  announcements: AnnouncementInterface[];
}) => {
  const announcement = announcements.find((x) => x.id === id);
  if (!announcement) return <></>;

  return (
    <BulletinAnnouncementWrapper
      title={announcement.title}
      testId={`announcement-${id}`}
      imageLink={announcement.image_link.String}
      imageAlt={announcement.image_alt.String}
      synopsis={parse(
        announcement.bulletin_text || (announcement.synopsis.String ?? ""),
        { trim: true }
      )}
      readMoreLink={"/get-plugged-in/latest/" + announcement.slug}
    />
  );
};

function UpcomingMeetings(props: { html: string }) {
  return (
    <BulletinAnnouncementWrapper
      id="upcoming-meetings"
      title="Meetings in the Coming Week"
      synopsis={parse(props.html, { trim: true })}
    />
  );
}

function BulletinAnnouncementWrapper(props: {
  title: string;
  id?: string;
  testId?: string;
  imageLink?: string | null;
  imageAlt?: string | null;
  synopsis: string | JSX.Element | JSX.Element[];
  readMoreLink?: string;
}) {
  const [size, setSize] = useState("");

  useEffect(() => {
    if (!props.imageLink || props.imageLink.length === 0) return;
    const img = new Image();
    img.src = props.imageLink;
    img.onload = () => {
      if (img.naturalWidth / img.naturalHeight > 1.4) {
        setSize("large");
      } else {
        setSize("small");
      }
    };
  }, [props.imageLink]);

  const content = (
    <>
      <AnnouncementImage link={props.imageLink} alt={props.imageAlt} />
      <div className="announcement-content">
        <div className="announcement-title">{props.title}</div>
        <div className="announcement-synopsis">{props.synopsis}</div>
      </div>
    </>
  );

  if (props.readMoreLink)
    return (
      <Link
        id={props.id}
        className={`announcement-box image-${size}`}
        data-testid={props.testId}
        to={props.readMoreLink}
      >
        {content}
      </Link>
    );

  return (
    <div
      id={props.id}
      className={`announcement-box image-${size}`}
      data-testid={props.testId}
    >
      {content}
    </div>
  );
}

function AnnouncementImage(props: {
  link?: string | null;
  alt?: string | null;
}) {
  if (props.link == null || props.link.length === 0) return <></>;
  return <img alt={props.alt || ""} src={props.link} />;
}
