import { ReactNode } from "react";
import { EmailLink } from "../../components/contact-components/EmailLink/EmailLink";
import { Phone } from "../../components/contact-components/Phone/Phone";
import { WebLink } from "../../components/contact-components/WebLink/WebLink";

export interface SpeakersAndOpportunitiesInterface {
  date: string;
  speaker: string;
  content: ReactNode;
}

export const SpeakersAndOpportunitiesData: SpeakersAndOpportunitiesInterface[] = [
  {
    date: "11 Aug",
    speaker: "Tan Lai Yong",
    content:
      <p>
        <em>Local Missions & Social Action Through Feed5K</em>
        <br />
        Be a blessing through volunteering in the neighbourhood! Feed5K opportunities include delivering bento meals to the clients served by New Hope Community Services, serving needy seniors through befriending them, or reaching out to the homeless through outreach walks in the evening.
        <br /><br />
        Bring your talents and time to serve the less fortunate amongst us. If you are interested or have any queries, please email contact us at <EmailLink to="feed5k@bfec.org.sg" />.
      </p>
  },
  {
    date: "18 Aug",
    speaker: "Pastor Patrick Park (TWR)",
    content:
      <>
        <p>
          Join TWR in the work of reaching Japan for Christ! We are conducting Donkey Tracting mission trips to reach the people of Japan through mass media.
          <br />
        </p>
        <p className="list-header">Dates for 2025:</p>
        <ul>
          <li>
            9 - 16 March 2025 — Nagasaki
          </li>
          <li>
            2 - 9 June 2025 — Nagoya
          </li>
          <li>
            15 - 22 September 2025 — Tohoku
          </li>
        </ul>
        <p>
          For more information - contact Jonathan at <Phone num="93823326" /> or email <EmailLink to="jchen@twr.org" />.
        </p>
      </>
  },
  {
    date: "25 Aug",
    speaker: "Simon Wan (Wycliffe)",
    content:
      <>
        <p>
          In Wycliffe, we invite you to join us in making the Word of God available in the heart languages of communities which we serve in. It “takes a village” to do this and the ministry requires more than translators but a variety of roles.
        </p>
        <ol>
          <li>
            <a href="https://www.wycliffe.sg/pray">Pray for us</a> — Sign up for our prayer mailing list, and be informed about the needs of projects in our region and beyond.
          </li>
          <li>
            <a href="https://www.wycliffe.sg/serve">Serve with us</a> — We have short-term, and long-term roles in both language and support areas, as well as internships. Whatever your experience, there is something you can do!
          </li>
          <li>
            <strong>Vision/Mission Trips</strong> — We organise trips to the field in neighbouring countries to observe how Bible translation work is done; and also to serve the communities there in various ways eg. English camps or conducting training for churches. These are usually 'customised/co-created' trips with supporting churches or groups.<br /> Contact <EmailLink to="simon_wan@wyclife.sg" /> for more info.
          </li>
        </ol>
        <p>
          Visit us at <WebLink link="https://www.wycliffe.sg/be-involved" />. We appreciate the partnership of BFEC with us.
        </p>
      </>
  },
  {
    date: "1 Sept",
    speaker: "Dr Chua Thien Seng (El Shaddai)",
    content:
      <p className="list-header">
        Details to be announced
      </p>
  },
  {
    date: "8 Sep",
    speaker: "Rodney Hui (OM)",
    content:
      <p>
        <strong>Individuals</strong> — Join the OM Ship ministry for 2-3 months, or 1-2 years for longer -term commitment.
        <br />
        <strong>Japan earthquake relief work</strong> — max. 6 pax in a team, or go as an individual. Flexible timing. Mainly cooking and delivering hot meals to affected locals.
        <br />
        <strong>Poland refugees ministry</strong> —  min. 1 week (arriving Sun and departing Sat), flexible timing. Mainly children and women ministry.
      </p>
  },
  {
    date: "15 Sept",
    speaker: "Daniel Lau",
    content:
      <>
        <p>
          <span className="list-header">OMF Prayer Journey (1-2 week trips)</span>
          <br />
          Come and join us for a trip of on-location intercession. Participants will see the work of OMF in these countries and engage with missionaries serving there.
          <br />
          We recognize the need for prayer and invite you to come help prepare the way for the Good News to be spread in these countries.
        </p>
        <ul>
          <li>
            <a href="https://opportunities.omf.org/mission-asia/detail/Prayer-Journey-%E2%80%93-South-Tohoku/50424952">
              Japan (South Tohoku) 7-19 Oct 2024
            </a>
          </li>
          <li>
            <a href="https://opportunities.omf.org/mission-asia/detail/Prayer-walking-and-vision-trip-(KH)/50401111">
              Cambodia, Sept-Oct 2024
            </a>
          </li>
          <li>
            <a href="https://opportunities.omf.org/mission-asia/detail/Prayer-Walking-in-South-East-Asia/50416045">
              Indonesia, after Jan 2025
            </a>
          </li>
        </ul>
      </>
  },
];
