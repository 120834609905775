import { useMediaQuery } from "@mui/material";
import { Tithing, WhatsOnSideBar } from "./WhatsOnSideBar";
import { PostsListing } from "../../components/ListingWithSearch/PostsListing/PostsListing";
import { usePageUtils } from "../usePageUtils";

export const WhatsOnHome = () => {
  usePageUtils().setTitleAndGroup("Latest", "Get Plugged In");

  const desktop = useMediaQuery("(min-width:1040px)");
  return (
    <section id="whats-on">
      <WhatsOnSideBar desktop={desktop} />
      <div style={{ flex: 1 }}>
        <Psalm96FM />
        <PostsListing />
        {!desktop && <Tithing />}
      </div>
    </section>
  );
};

const Psalm96FM = () => {
  return (
    <div id="psalm96fm">
      <div id="blurb">
        Listen in to PSALM 96 FM (Frankel Music) and sing a new song with us!
        Share this playlist with your cell on whatapp/tele.
      </div>
      <div id="player">
        <iframe
          title="Psalm96FM Spotify Playlist"
          style={{ borderRadius: "12px", border: 0 }}
          src="https://open.spotify.com/embed/playlist/6nSgWXk5pysjfzVHGQ8aJO?utm_source=generator"
          width="100%"
          height="152"
          allowFullScreen={false}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </div>
    </div>
  );
};
