import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AnnouncementInterface,
  Bulletin,
  makeSermonFixture,
  SermonInterface,
  ServiceTagData,
  SpeakerSeriesData,
  TestimonyInterface,
} from "../components/Interfaces/Interfaces";
import { makeFixture } from "../testing/MockFirebaseUser";
import { PublicAPI } from "./PublicAPI";
import * as Sentry from "@sentry/react";
import { Loading } from "../components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { trackPageView } from "./googleAnalytics";

const shouldScroll = (prevPathname: string, newPathname: string) => {
  if (prevPathname === "/" || newPathname === "/") {
    return true;
  }

  if (prevPathname === newPathname) return false;

  if (
    prevPathname.startsWith("/get-plugged-in/latest/") &&
    newPathname.startsWith("/get-plugged-in/latest/")
  )
    return false;

  if (
    prevPathname.startsWith("/get-plugged-in/sermons/") &&
    newPathname.startsWith("/get-plugged-in/sermons/")
  )
    return false;

  return !(
    prevPathname.startsWith(newPathname) || newPathname.startsWith(prevPathname)
  );
};

export interface PublicContextInterface {
  announcements: AnnouncementInterface[] | null;
  latestSermon: SermonInterface | null;
  sermons: SermonInterface[] | null;
  testimonies: TestimonyInterface[] | null;
  speakers: SpeakerSeriesData[] | null;
  series: SpeakerSeriesData[] | null;
  services: ServiceTagData[] | null;
  tags: ServiceTagData[] | null;
  bulletins: { [k: string]: Bulletin };
  prevPathname: string;
}

export const defaultPublicContext: PublicContextInterface = {
  announcements: null,
  latestSermon: null,
  sermons: null,
  testimonies: null,
  speakers: null,
  series: null,
  services: null,
  tags: null,
  bulletins: {},
  prevPathname: "",
};

export const buildPublicContextFixture = (
  overrides: Partial<PublicContextInterface> = {}
) => {
  return makeFixture(
    {
      announcements: null,
      latestSermon: null,
      sermons: null,
      testimonies: null,
      speakers: null,
      series: null,
      services: null,
      tags: null,
      bulletins: {},
      prevPathname: "",
    },
    overrides
  );
};

export const PublicContextProvider = (props: { children: React.ReactNode }) => {
  const [announcements, setAnnouncements] = useState<
    AnnouncementInterface[] | null
  >(null);
  const [latestSermon, setLatestSermon] = useState<SermonInterface | null>(
    null
  );
  const [sermons, setSermons] = useState<SermonInterface[] | null>(null);
  const [testimonies, setTestimonies] = useState<TestimonyInterface[] | null>(
    null
  );
  const [speakers, setSpeakers] = useState<SpeakerSeriesData[] | null>(null);
  const [series, setSeries] = useState<SpeakerSeriesData[] | null>(null);
  const [services, setServices] = useState<ServiceTagData[] | null>(null);
  const [tags, setTags] = useState<ServiceTagData[] | null>(null);
  const [bulletins, setBulletins] = useState<{ [k: string]: Bulletin }>({});

  const prevPathname = useRef("/");
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView(pathname);

    if (shouldScroll(prevPathname.current, pathname)) {
      window.scrollTo(0, 0);
    }

    prevPathname.current = pathname;
  }, [pathname]);

  useEffect(() => {
    window.onfocus = function () {
      fetchLatestData();
    };
  }, []);

  const fetchData = async () => {
    const fetched = await PublicAPI().getData();
    if (fetched?.status === 200) {
      setAnnouncements(fetched.data.announcements);
      setSermons(fetched.data.sermons);
      setTestimonies(fetched.data.testimonies);
      setSpeakers(fetched.data.speakers);
      setSeries(fetched.data.series);
      setServices(fetched.data.services);
      setTags(fetched.data.tags);
      setBulletins(fetched.data.bulletins);
    }
  };

  const fetchLatestData = async () => {
    const fetched = await PublicAPI().getLatestSermon();
    if (fetched?.status === 200) {
      setLatestSermon(fetched.data);
    }
  };

  useEffect(() => {
    fetchLatestData().catch((err) => {
      Sentry.captureException(err);
      console.log(err);
    });

    fetchData().catch((err) => {
      Sentry.captureException(err);
      console.log(err);
    });
  }, []);

  if (!latestSermon) return <Loading />;

  return (
    <PublicContext.Provider
      value={{
        announcements,
        latestSermon,
        sermons,
        testimonies,
        speakers,
        series,
        services,
        tags,
        bulletins,
        prevPathname: prevPathname.current,
      }}
    >
      {props.children}
    </PublicContext.Provider>
  );
};

export const FixturePublicContextProvider = (props: {
  children: React.ReactNode;
}) => (
  <PublicContext.Provider
    value={buildPublicContextFixture({ latestSermon: makeSermonFixture() })}
  >
    {props.children}
  </PublicContext.Provider>
);

export const PublicContext = React.createContext(defaultPublicContext);

export const usePublicContext = () => useContext(PublicContext);
