import { EmailLink } from "../contact-components/EmailLink/EmailLink";
import { Phone } from "../contact-components/Phone/Phone";

export const BulletinAddress = () => (
  <div className="print-only">
    <div id="address">
      <div>
        <b>4 La Salle Street, Singapore 456930</b>
      </div>
      <div>
        <b>Tel:</b> <Phone num={"6448 1800"} />
      </div>
      <div>
        <b>Fax:</b> <Phone num={"6448 0831"} />
      </div>
      <div>
        <b>Email:</b> <EmailLink to={"bfec"} />
      </div>
      <div>
        <b>Website:</b> <a href="https://bfec.org.sg">https://bfec.org.sg</a>
      </div>
      <div>
        <b>Church Office Opening Hours:</b> Mon-Fri: 9am-6pm Sat & Sun: 9am-1pm
      </div>
    </div>
  </div>
);
