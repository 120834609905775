export function BulletinHeader(props: {
  formattedSermonDate: string;
  headerPrimary: string;
  headerSecondary: string;
}) {
  return (
    <div id="bulletin-header" aria-label="Bulletin header">
      <div className="print-only">
        <div id="sermon-date">{props.formattedSermonDate}</div>
      </div>
      {props.headerPrimary.length > 0 && <h1>{props.headerPrimary}</h1>}
      {props.headerSecondary.length > 0 && (
        <div className="subtitle">{props.headerSecondary}</div>
      )}
    </div>
  );
}
