import { SermonInterface } from "../Interfaces/Interfaces";
import { format } from "date-fns";
import parse from "html-react-parser";

export function BulletinSermonDetails(props: {
  sermon: SermonInterface;
  sermonNotes: string;
}) {
  const formattedSermonDate = formatSermonDate(props.sermon);

  const serviceText = props.sermon.service_data
    .map((x) => x.service.name)
    .join(" & ")
    .replace("Service &", "&");

  const sermonPassage =
    props.sermon.main_passage?.length > 0
      ? ` (${props.sermon.main_passage})`
      : "";

  const speakers = props.sermon.speaker.map((x) => x.name).join(" & ");

  return (
    <div id="sermon">
      <div id="sermon-details">
        <b>{props.sermon.title}</b> {sermonPassage} by <b>{speakers}</b> at{" "}
        {serviceText}
        <span className="screen-only"> ({formattedSermonDate})</span>
      </div>
      <div id="sermon-notes">{parse(props.sermonNotes, { trim: true })}</div>
    </div>
  );
}

export function formatSermonDate(sermon: SermonInterface) {
  const sermonDate = new Date(sermon.sermon_date.Time);
  return format(sermonDate, "d MMM y");
}
