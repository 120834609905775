import { Phone } from "../contact-components/Phone/Phone";

export const BulletinGivingMessage = (props: {
  screen?: boolean;
  print?: boolean;
}) => (
  <div
    className={
      (props.screen ? "screen-only " : "") + (props.print ? "print-only " : "")
    }
  >
    <div id="giving">
      <div className="column" style={{ flex: 2.1 }}>
        <div>
          <b>My Response:</b> Our Response during the service is an act of
          worship. Do submit your reflections online to{" "}
          <a href="https://bfec.sg/response">https://bfec.sg/response</a> or via
          whatsapp or sms to <Phone num="9670 1800" />.
        </div>
        <div>
          <b>Holy Communion:</b> We welcome baptized believers to join in the
          Lord’s Supper.
        </div>
        <div>
          <b>Tithes & Offerings:</b> Giving as "unto the Lord" is the privilege
          of every disciple of Christ. As an act of personal & corporate
          worship, we encourage members to participate purposefully through
          regular giving during worship services.
        </div>
      </div>
      <div className="column" style={{ flex: 1 }}>
        <div className="giving-information">
          <div>
            <b>Internet Banking:</b> Bank Code: 7375
          </div>
          <GivingInformation
            genFund="(UOB 451-302-912-2)"
            missionsFund="(UOB 451-302-915-7)"
          />
        </div>
        <div className="giving-information">
          <div>
            <b>PayNow:</b> for QR code, pls refer to{" "}
            <a href="https://bfec.sg/give">https://bfec.sg/give</a>
          </div>
          <GivingInformation
            genFund="UEN 197302391EA01"
            missionsFund="UEN 197302391EA02"
          />
        </div>
      </div>
    </div>
  </div>
);

const GivingInformation = (props: {
  genFund: string;
  missionsFund: string;
}) => {
  return (
    <div className="giving-information-details">
      <div>
        <span>Gen & Ministry Fund:</span>
        <span>{props.genFund}</span>
      </div>
      <div>
        <span>Missions Fund:</span>
        <span>{props.missionsFund}</span>
      </div>
    </div>
  );
};
