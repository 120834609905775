import { makeFixture } from "../../testing/MockFirebaseUser";
import { faker } from "@faker-js/faker";

export interface SQLNullTime {
  Time: string;
  Valid: boolean;
}

export const nowSQLNullTime: SQLNullTime = {
  Time: new Date().toISOString(),
  Valid: true,
};

export const makePastSQLNullTime = (): SQLNullTime => ({
  Time: faker.date.past().toISOString(),
  Valid: true,
});

export const emptySQLNullTime: SQLNullTime = {
  Time: "0001-01-01T00:00:00Z",
  Valid: false,
};

export interface SQLNullString {
  String: string | null;
  Valid: boolean;
}

export const emptySQLNullString: SQLNullString = {
  String: "",
  Valid: false,
};

export interface ServiceTagData {
  id?: number;
  name: string;
  input_value?: string;
}

const makeServiceTagDataFixture = (overrides: Partial<ServiceTagData> = {}) => {
  return makeFixture(
    {
      id: faker.number.int(),
      name: faker.word.noun(),
      input_value: faker.lorem.words(),
    },
    overrides
  );
};

export const emptyServiceTagData: ServiceTagData = {
  id: 0,
  name: "",
};

export interface File {
  id: number;
  file_link: string;
  file_text?: string;
  file_hash: string;
  uploaded: string;
  category: string;
}

export interface OrderedFile {
  file_id: number;
  file_link: string;
  order: number;
}

export interface SpeakerSeriesData {
  id: number;
  name: string;
  image_link: SQLNullString;
  content: string;
}

export const emptySpeakerSeries: SpeakerSeriesData = {
  id: 0,
  name: "",
  content: "",
  image_link: { String: "", Valid: false },
};

export const makeSpeakerSeriesFixture = (
  overrides: Partial<SpeakerSeriesData> = {}
) => {
  return makeFixture(
    {
      id: faker.number.int(),
      name: faker.person.fullName(),
      content: faker.lorem.sentences(),
      image_link: makeSqlNullStringFixture(),
    },
    overrides
  );
};

export interface ServiceData {
  service: ServiceTagData;
  video_link: string;
  video_embed: string;
}

export const makeServiceDataFixture = (
  overrides: Partial<ServiceData> = {}
) => {
  return makeFixture(
    {
      service: makeServiceTagDataFixture(overrides.service),
      video_link: faker.internet.url(),
      video_embed: faker.internet.url(),
    },
    overrides
  );
};

export interface SermonInterface {
  // sermon metadata
  id: number;
  title: string;
  sermon_date: SQLNullTime;
  slug: string;
  main_passage: string;
  speaker: SpeakerSeriesData[];
  synopsis: string;
  series: SpeakerSeriesData;

  // services
  service_data: ServiceData[];
  bulletin: string;
  sermon_notes: string;
  files: OrderedFile[];

  // admin metadata
  author: string;
  tags: ServiceTagData[];
  created_on: SQLNullTime;
  last_edited: SQLNullTime;
  last_edited_by: SQLNullString;
  published_on: SQLNullTime;
}

export const emptySermon: SermonInterface = {
  id: 0,
  title: "",
  sermon_date: nowSQLNullTime,
  slug: "",
  main_passage: "",
  speaker: [],
  synopsis: "",
  series: emptySpeakerSeries,

  service_data: [],

  bulletin: "",
  sermon_notes: "",
  files: [],

  author: "",
  tags: [],
  created_on: nowSQLNullTime,
  last_edited: emptySQLNullTime,
  last_edited_by: emptySQLNullString,
  published_on: emptySQLNullTime,
};

export const makeEmptySermonFixture = (
  overrides: Partial<SermonInterface> = {}
) => {
  return makeFixture(emptySermon, overrides);
};

export const makeSermonFixture = (overrides: Partial<SermonInterface> = {}) => {
  return makeFixture(
    {
      id: faker.number.int(),
      title: faker.music.songName(),
      sermon_date: makePastSQLNullTime(),
      slug: faker.lorem.slug(),
      main_passage: faker.person.firstName(),
      speaker: [],
      synopsis: faker.lorem.paragraph(),
      series: emptySpeakerSeries,

      service_data: [],

      bulletin: "",
      sermon_notes: "",
      files: [],

      author: "",
      tags: [],
      created_on: makePastSQLNullTime(),
      last_edited: makePastSQLNullTime(),
      last_edited_by: {
        Valid: true,
        String: faker.person.fullName(),
      },
      published_on: makePastSQLNullTime(),
    },
    overrides
  );
};

export interface AnnouncementInterface {
  id: number;
  title: string;
  image_link: SQLNullString;
  image_alt: SQLNullString;
  content: string;
  bulletin_text: string;
  slug: string;
  author: string;
  created_on: SQLNullTime;
  last_edited: SQLNullTime;
  last_edited_by: SQLNullString;
  published_on: SQLNullTime;
  pinned: boolean;
  files: OrderedFile[];
  synopsis: SQLNullString;
}

export const emptyAnnouncement: AnnouncementInterface = {
  id: 0,
  title: "",
  image_link: emptySQLNullString,
  image_alt: emptySQLNullString,
  content: "",
  bulletin_text: "",
  slug: "",
  author: "",
  created_on: nowSQLNullTime,
  last_edited: emptySQLNullTime,
  last_edited_by: emptySQLNullString,
  published_on: emptySQLNullTime,
  pinned: false,
  files: [],
  synopsis: emptySQLNullString,
};

export const makeSqlNullStringFixture = (): SQLNullString => {
  return {
    Valid: true,
    String: faker.lorem.sentence(),
  };
};

export const makeSqlNullTimeFixture = (): SQLNullTime => {
  return {
    Valid: true,
    Time: faker.date.past().toISOString(),
  };
};

export const makeAnnouncementFixture = (
  overrides: Partial<AnnouncementInterface> = {}
) => {
  return makeFixture(
    {
      id: faker.number.int(),
      title: faker.lorem.sentence(),
      image_link: makeSqlNullStringFixture(),
      image_alt: makeSqlNullStringFixture(),
      content: faker.lorem.sentences(),
      bulletin_text: faker.lorem.sentences(),
      slug: faker.lorem.slug(),
      author: faker.person.fullName(),
      created_on: makeSqlNullTimeFixture(),
      last_edited: makeSqlNullTimeFixture(),
      last_edited_by: makeSqlNullStringFixture(),
      published_on: makeSqlNullTimeFixture(),
      pinned: false,
      files: [],
      synopsis: makeSqlNullStringFixture(),
    },
    overrides
  );
};

// Testimony Interface
export interface TestimonyInterface {
  id: number;
  title: string;
  content: string;
  slug: string;
  author: string;
  created_on: SQLNullTime;
  last_edited: SQLNullTime;
  last_edited_by: SQLNullString;
  published_on: SQLNullTime;
  files: OrderedFile[];
}

export const makeTestimonyFixture = (
  overrides: Partial<TestimonyInterface> = {}
): TestimonyInterface =>
  makeFixture(
    {
      author: faker.person.fullName(),
      content: faker.lorem.paragraph(),
      created_on: makePastSQLNullTime(),
      files: [],
      id: faker.number.int(),
      last_edited: makePastSQLNullTime(),
      last_edited_by: {
        Valid: true,
        String: faker.person.fullName(),
      },
      published_on: makePastSQLNullTime(),
      slug: faker.lorem.slug(),
      title: faker.commerce.productName(),
    },
    overrides
  );

export const emptyTestimony: TestimonyInterface = {
  id: 0,
  title: "",
  content: "",
  slug: "",
  author: "",
  created_on: nowSQLNullTime,
  last_edited: emptySQLNullTime,
  last_edited_by: emptySQLNullString,
  published_on: emptySQLNullTime,
  files: [],
};

export interface RestoreProgress {
  id: number;
  category: string;
  status: string;
  current_progress: number;
  total_progress: number;
  created_on: SQLNullTime;
  progress_timestamp: SQLNullTime;
  author: SQLNullString;
}

export interface Bulletin {
  sermon_id: number;
  post_type: string;
  header_primary: string;
  header_secondary: string;
  banner_link: string;
  sermon_notes: string;
  announcement_ids: number[];
  sermon_series_id?: number;
  upcoming_meetings: string;
  last_updated?: string;
  last_updated_by?: string;
}

export const makeBulletinFixture = (overrides: Partial<Bulletin> = {}) => {
  const defaultBulletin: Bulletin = {
    sermon_id: faker.number.int(),
    post_type: "published",
    header_primary: faker.company.name(),
    header_secondary: faker.company.catchPhrase(),
    banner_link: faker.internet.url(),
    sermon_notes: faker.lorem.paragraph(),
    announcement_ids: [],
    upcoming_meetings: faker.lorem.paragraph(),
  };

  return makeFixture(defaultBulletin, overrides);
};
